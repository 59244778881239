
import { Field, Form, ErrorMessage } from "vee-validate";
import { computed, defineComponent, nextTick, onMounted, ref } from "vue";
import * as Validations from "yup";
import { PasswordMeterComponent } from "@/assets/ts/components";
import { accountStore } from "@/store/modules/account";
import { useStore } from "vuex";
import ChangePasswordRequest from "@/models/user/ChangePasswordRequest";
import { PasswordPulmeros } from "@/core/shared/ValidationRegex";

export default defineComponent({
    components:{
        Form
        , Field
        , ErrorMessage
    },
    emits: ['close-content'],
    setup(_, {emit}) {
        const store = useStore();
        const validationSchema = Validations.object().shape({
            email: Validations.string().min(4).required().email().label("Email"),
            password: Validations.string().required().label("Password"),
            newPassword: Validations.string().required().matches(PasswordPulmeros, "No coincide con una contraseña valida").label("New Password"),
            confirmNewPassword: Validations.string()
                .matches(PasswordPulmeros, "No coincide con una contraseña valida")
                .required()
                .oneOf([Validations.ref("newPassword"), null], "La contraseña no coincide")
                .label("Password Confirmation"),
        });
        const emailUser = computed(() => store.getters["getEmailUser"])
        const loading = computed(() => accountStore.getLoading)

        //METODOS
        const submitForm = (formData: ChangePasswordRequest, { resetForm }) => {
            accountStore.CHANGE_PASSWORD(formData)
            .then((isCorrect) => { 
                if(isCorrect){
                    emit("close-content");
                    resetForm();
                    PasswordMeterComponent.bootstrap();
                }
            })
            .catch();

        }

        onMounted(() => {
            nextTick(() => {
                PasswordMeterComponent.bootstrap();
            });
        });

        return {
             validationSchema
            , submitForm
            //commputed
            , emailUser
            , loading
        }
    }
})
