
export enum Mutations {
    SET_VALUE_LOADING = "SET_VALUE_LOADING"
    , SET_RESPONSIBLES = "SET_RESPONSIBLES"
    , SET_CONCEPT_TYPES = "SET_CONCEPT_TYPES"
    , SET_STTLEMENT = "SET_STTLEMENT"
    , SET_TABLE = "SET_TABLE"
    , SET_TYPE_PAYMENT = "SET_TYPE_PAYMENT"
    , SET_SHIP = "SET_SHIP"
    , SET_CASH_REGISTER = "SET_CASH_REGISTER"
    , SET_REGISTER_PDF = "SET_REGISTER_PDF"
    , SET_SHOW_PDF = "SET_SHOW_PDF"
}