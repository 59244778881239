import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, mergeProps as _mergeProps, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "d-flex flex-column mb-8 fv-row" }
const _hoisted_2 = { class: "d-flex align-items-center fs-6 fw-bold mb-2" }
const _hoisted_3 = { class: "" }
const _hoisted_4 = { class: "d-flex flex-column mb-8 fv-row" }
const _hoisted_5 = { class: "fs-6 fw-bold mb-2" }
const _hoisted_6 = { class: "d-flex flex-column mb-8 fv-row" }
const _hoisted_7 = { class: "d-flex align-items-center fs-6 fw-bold mb-2" }
const _hoisted_8 = {
  ref: "submitButton",
  type: "submit"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SelectFilterRemote = _resolveComponent("SelectFilterRemote")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_Field = _resolveComponent("Field")!
  const _component_DatePickerRange = _resolveComponent("DatePickerRange")!
  const _component_Form = _resolveComponent("Form")!
  const _component_FilterDynamic = _resolveComponent("FilterDynamic")!

  return (_openBlock(), _createBlock(_component_FilterDynamic, {
    width: 450,
    onClearFilters: _ctx.clean,
    onApply: _ctx.apply
  }, {
    default: _withCtx(() => [
      _createVNode(_component_Form, {
        as: "el-form",
        "validation-schema": _ctx.validationSchema,
        ref: "form",
        onSubmit: _ctx.onSubmit
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("label", _hoisted_2, [
              _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t("LABELS.BOAT_ID")), 1)
            ]),
            _createVNode(_component_SelectFilterRemote, {
              multiple: true,
              name: 'shipIds',
              placeholder: _ctx.$t('PLACE_HOLDERS.BOAT_ID'),
              options: _ctx.boatsOptions,
              onSearch: _ctx.searchBoats,
              loading: _ctx.loadingShip
            }, null, 8, ["placeholder", "options", "onSearch", "loading"])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("label", _hoisted_5, _toDisplayString(_ctx.$t("LABELS.LICENSE_TYPE_ID")), 1),
            _createVNode(_component_Field, { name: "typeFishingId" }, {
              default: _withCtx(({ value, field, errorMessage }) => [
                _createVNode(_component_el_form_item, { error: errorMessage }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_select, _mergeProps({
                      placeholder: _ctx.$t('PLACE_HOLDERS.LICENSE_TYPE_ID')
                    }, field, {
                      "validate-event": false,
                      "model-value": value
                    }), {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.licenseTypeOptions, (option) => {
                          return (_openBlock(), _createBlock(_component_el_option, {
                            key: option.id,
                            label: option.name,
                            value: option.id
                          }, null, 8, ["label", "value"]))
                        }), 128))
                      ]),
                      _: 2
                    }, 1040, ["placeholder", "model-value"])
                  ]),
                  _: 2
                }, 1032, ["error"])
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("label", _hoisted_7, _toDisplayString(_ctx.$t("LABELS.RANGE_DATES")), 1),
            _createVNode(_component_DatePickerRange, {
              placehoderDateEnd: _ctx.$t('PLACE_HOLDERS.END_DATE'),
              placehoderDateStart: _ctx.$t('PLACE_HOLDERS.START_DATE')
            }, null, 8, ["placehoderDateEnd", "placehoderDateStart"])
          ]),
          _withDirectives(_createElementVNode("button", _hoisted_8, null, 512), [
            [_vShow, false]
          ])
        ]),
        _: 1
      }, 8, ["validation-schema", "onSubmit"])
    ]),
    _: 1
  }, 8, ["onClearFilters", "onApply"]))
}