
import { defineComponent, ref } from "vue"
import ChangePassword from "../forms/ChangePassword.vue"
import DynamicContentModal from "@/components/modals/general/DynamicContentModal.vue"
import { hideModalById } from "@/core/helpers/dom";

export default defineComponent({
    components:{
        ChangePassword
        , DynamicContentModal
    }, methods:{
        cleanForm(){
            //this.$refs.form.clean();
        }
    }
    , setup(){
        const modalRef = ref(null);
        const closeModal = () => {
            hideModalById("#change-password-form");
        };
        return {
            modalRef
            , closeModal
        }
    }
})
