export enum Actions {
    // action types
    SAVE_NEW = "SAVE_NEW_REQUEST"
    , UPDATE_REQUEST = "UPDATE_REQUEST"
    , PYMENT_REQUEST = "PYMENT_REQUEST"
    , SEARCH_RESPONSIBLES = "SEARCH_RESPONSIBLES"
    , GET_STTLEMENT = "GET_STTLEMENT"
    , GET_TYPE_CONCEPT = "GET_TYPE_CONCEPT"
    , GET_TABLE = "GET_TABLE"
    , GET_TYPE_PAYMENT = "GET_TYPE_PAYMENT"
    , SEARCH_SHIP = "SEARCH_SHIP"
    , GET_CASH_REGISTER = "GET_CASH_REGISTER"
    , GET_REGISTER_PDF = "GET_REGISTER_PDF"
    , CANCEL_REQUEST = "CANCEL_REQUEST"
    , SHOW_PDF = "SHOW_PDF"
  }