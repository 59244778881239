
import { computed, defineComponent, ref } from "vue";
import {FiltersStadisticRequest} from "@/core/shared/models/Stadistic/FiltersStadisticRequest"
import FilterDynamic from "@/components/filters/FiltersDynamic.vue"
import { Field, Form } from "vee-validate";
import * as Validations from "yup";
import { DateStringISO8601 } from "@/core/shared/ValidationRegex";
import SelectFilterRemote from "@/components/forms/SelectFilterRemote.vue"
import DatePickerRange from "@/components/forms/DatePickerRange.vue"
import _ from "lodash";
import { getModule } from "vuex-module-decorators";
import DashboardModule from "@/store/modules/dashboard"
export default defineComponent({
    components:{
        FilterDynamic
        , Form
        , Field
        , SelectFilterRemote
        , DatePickerRange
    },
    setup(){
        const module = getModule(DashboardModule);
        const form = ref();
        const submitButton = ref<HTMLButtonElement>();
        const validationSchema = Validations.object().shape({
            shipIds: Validations.array(Validations.string()).nullable().default([])
            , typeFishingId: Validations.string().nullable().default('')
            , startDate: Validations.string().nullable().trim().matches(DateStringISO8601)
            , endDate: Validations.string().nullable().trim().matches(DateStringISO8601)
        })
        //module.GET_LICENSE_TYPES();

        const clean = () => {
            form.value.resetForm();
        }
        const apply = () => {
            submitButton.value?.click();
        }

        const searchBoats = _.debounce((query: string) => {
            //module.SEARCH_SHIP(query);
        }, 550, {leading: false, trailing: true});

        const onSubmit = (values) => {
            //module.UPDATE_FILTERS(values);
        }

        //store
        const boatsOptions = computed(() => module.getShipOptions);
        const loadingShip = computed(() => module.getShipLoading);
        const licenseTypeOptions = computed(() => module.getFishingTypeOptions);
        return {
            apply
            , clean
            , form
            , validationSchema
            , boatsOptions
            , searchBoats
            , loadingShip
            , licenseTypeOptions
            , submitButton
            , onSubmit
        }
    }
})
