
import { computed, defineComponent } from "vue";
import { toolbarWidthFluid } from "@/core/helpers/config";
import { useRouter } from "vue-router";
import { RouteNames } from "@/router/route-names";
import Filters from "@/views/dashboard/Filters.vue";
import FilterReportMovements from "@/views/cashregister/components/FilterReportMovements.vue";

export default defineComponent({
  name: "KToolbar",
  props: {
    breadcrumbs: Array,
    title: String,
  },
  components: {
    Filters
    , FilterReportMovements
  },
  setup() {
    const router = useRouter();

   //Propiedades
   const isViewDashboard = computed(() => router.currentRoute.value.name == RouteNames.Dashboard);

   const isViewCashRegister = computed(() => router.currentRoute.value.name == RouteNames.CashRegister);
   
   router.currentRoute.value.name;
    return {
      toolbarWidthFluid
      , isViewDashboard
      , isViewCashRegister
    };
  },
});
