
import { computed, defineComponent, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { version } from "@/core/helpers/documentation";
import { asideMenuIcons } from "@/core/helpers/config";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "kt-menu",
  components: {},
  setup() {
    const store = useStore(); 
    const route = useRoute();
    const scrollElRef = ref<null | HTMLElement>(null);

    store.dispatch(Actions.RESET_MENUCONFIG);

    //STORE
    const configMenu = computed(() => store.getters["getMenuConfig"]);

    onMounted(() => {
      if (scrollElRef.value) {
        scrollElRef.value.scrollTop = 0;
      }
    });

    const hasActiveChildren = (match) => {
      if(match == '') return false; 
      return route.path.indexOf(match) > -1;
    };

    return {
      hasActiveChildren,
      configMenu,
      asideMenuIcons,
      version,
    };
  },
});
