import SwalMessageService from '@/core/services/SwalMessageService';
import NewRequest from "@/models/cash-register/NewRequest";
import ApiService from "../ApiService";
import GeneralService from "../interfaces/GeneralService";
import MessageService from "../interfaces/MessageService";
import { translate } from '@/core/plugins/i18n';
import { AxiosResponse } from 'axios';
import GetAllCashRegisterResponse from '@/models/cash-register/GetAllCashRegisterResponse';
import PaginationResponse from '@/models/general/PaginationResponse';
import GetConceptTypeResponse from '@/models/cash-register/GetConceptTypeResponse';
import GetSttlementResponse from '@/models/cash-register/GetSttlementResponse';
import GetCashRegisterResponse from '@/models/cash-register/GetCashRegisterResponse';
import GetAllCashRegisterCriterialPaginator from '@/models/cash-register/GetAllCashRegisterCriterialPaginator';
import ResponsibleRequest from '@/models/cash-register/ResponsibleRequest';
import ResponsibleResponse from '@/models/cash-register/ResponsibleResponse';
import { getMessageError, getMessageHeader } from '@/core/helpers/messageFromPulmeros';

/**
 * Servicio de la seccion Caja
 */
export default class CashRegisterService implements GeneralService {
    apiController = "/TakeMovement";
    messageService: MessageService = new SwalMessageService();
    
    /**
     * Guarda los datos de la nueva solictud
     * @param RequestInfo Información de la nueva Solicitud
     * @returns Un indicador para saber si se guardo correctamente el registro
     */
    async saveNewRequest(takeMovementCreationDto: NewRequest): Promise<AxiosResponse<boolean>> {
        return ApiService.post<boolean>(this.apiController
            ,  takeMovementCreationDto
        ).then( (resp) => {
            return resp;
        }).catch(error => {
            getMessageError(error, this.messageService);
            return {status: 500, data: null} as AxiosResponse;
        });
    }

    /**
     * Actualiza los datos de la nueva solictud
     * @param RequestInfo Información de la Solicitud
     * @returns Un indicador para saber si se actualizo correctamente el registro
     */
     async updateRequest(takeMovementCreationDto: NewRequest): Promise<AxiosResponse<boolean>> {
        return ApiService.update<boolean>(this.apiController, `${takeMovementCreationDto.id}`
            , takeMovementCreationDto 
        ).then( (resp) => {
            return resp;
        }).catch(error => {
            getMessageError(error, this.messageService);
            return {status: 500, data: null} as AxiosResponse;
        });
    }

    /**
     * Aplica pago de las solicitudes
     * @param filterName Datos de la solicitud
     * @returns Respuesta de axios con los datos
     */
    async paymentRequest(id: string): Promise<AxiosResponse<boolean>> {
        return ApiService.post<boolean>(this.apiController + `/${id}/ApplyPayment`, {}
        ).then( (resp) => {
            return resp;
        }).catch(error => {
            getMessageError(error, this.messageService);
            return { data: null} as AxiosResponse;
        });
    }

    /**
     * Cancela Pago
     * @param id Identificador solicitud
     * @returns Respuesta de axios con los datos
     */
     async cancelRequest(id: string): Promise<AxiosResponse<boolean>> {
        return ApiService.post<boolean>(this.apiController + `/${id}/Cancel`, {}
        ).then( (resp) => {
            return resp;
        }).catch(error => {
            getMessageError(error, this.messageService);
            return { data: null} as AxiosResponse;
        });
    }

    /**
     * Consulta las opciones de los responsables
     * @param filterName Nombre del responsable a filtrar
     * @returns Respuesta de axios con los datos
     */
    async searchResponsibles(filter: ResponsibleRequest): Promise<AxiosResponse<Array<ResponsibleResponse>>> {
        let params =  `combo?GetAccountsPayable=${filter.accountsPayable}&Providers=false`;
        
        if(filter.isLiquidation){
            params =  `combo?Providers=false`;
        }

        return  ApiService.get<Array<ResponsibleResponse>>('/Suppliers', 
                params
        ).then( resp => {
            return resp;
        }).catch(error => {
            getMessageError(error, this.messageService);
            return { data: null} as AxiosResponse;
        });
    }

    /**
     * Consulta las lista de liquidaciones
     * @param filterName Filtro de la liquidación
     * @returns Respuesta de axios con los datos
     */
     async getSttlement(filterName: string): Promise<AxiosResponse<Array<GetSttlementResponse>>> {
        return  ApiService.get<Array<GetSttlementResponse>>('/Liquidation', 
           `GetComboList?criteria=${filterName}`
        ).then( resp => {
            return resp;
        }).catch(error => {
            getMessageError(error, this.messageService);
            return { data: null} as AxiosResponse;
        });
    }

    /**
     * Consulta la información de una solicitud
     * @param id indentificador de la solicitud
     * @returns Respuesta de axios con los datos
     */
     async getCashRegisterInfo(id: string): Promise<AxiosResponse<GetCashRegisterResponse>> {
        return  ApiService.get<GetCashRegisterResponse>(this.apiController, 
           `${id}`
        ).then( resp => {
            return resp;
        }).catch(error => {
            getMessageError(error, this.messageService);
            return { data: null} as AxiosResponse;
        });
    }

    /**
     * Busca y retorna la lista de tipos de pagos
     * @returns Lista de opciones para los tipos de conceptos
     */
     async getPaymentType(): Promise<AxiosResponse<GetConceptTypeResponse[]>>{
        return ApiService.get<GetConceptTypeResponse[]>("/PaymentType", 
            "GetComboList")
            .then( resp => {
                return resp;
            }).catch(error => {
                getMessageError(error, this.messageService);
                return { data: null} as AxiosResponse;
            });
    }

    /**
     * Busca y retorna la lista de conceptos
     * @returns Lista de opciones para los tipos de licencia
     */
    async getTypeConcept(): Promise<AxiosResponse<GetConceptTypeResponse[]>>{
        return ApiService.get<GetConceptTypeResponse[]>("/ConceptType", 
            "GetComboList")
            .then( resp => {
                return resp;
            }).catch(error => {
                getMessageError(error, this.messageService);
                return { data: null} as AxiosResponse;
            });
    }


    /**
     * Busca los datos de la tabla de caja
     * @param paginator datos de paginación para consultar
     * @returns datos de paginado con los datos de la tabla
     */
    async getTable(paginator: GetAllCashRegisterCriterialPaginator): Promise<AxiosResponse<PaginationResponse<GetAllCashRegisterResponse[]>>>{
        let params = `TakeStatus=${paginator.status}&CurrentPage=${paginator.currentPage}&PageSize=${paginator.pageSize}`;
        
        if(paginator.criterial != ""){
            params += `&Person=${paginator.criterial}`;
        }

        return ApiService.get<PaginationResponse<GetAllCashRegisterResponse[]>>(this.apiController, 
            `GetPagedList?${params}`
        )
        .then(resp => {
            return resp;
        }).catch(error => {
            getMessageError(error, this.messageService);
            return { data: null} as AxiosResponse;
        });
    }

    /**
     * Recupera PDF
     * @param id identificador de la liquidación
     * @returns indicador
     */
     async GetReceiptView(id: string): Promise<AxiosResponse<boolean>>{
        return  ApiService.get<boolean>(this.apiController + `/GetReceiptView/${id}`
        ).then( resp => {
            return resp;
        }).catch(error => {
            getMessageError(error, this.messageService);
            return { data: null} as AxiosResponse;
        });
    }
}