export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "es": {
        "TIP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usa 6 o más caracteres que contengan una convinación entre letras mayúsculas y minúsculas, numeros y simbolos."])},
        "LABELS": {
          "EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo"])},
          "PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraseña"])},
          "NEW_PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nueva Contraseña"])},
          "CONFIRM_NEW_PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar Contraseña"])},
          "CONFIRM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar cambio"])},
          "PLEASE_WAIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espera porfavor..."])}
        }
      }
    }
  })
}
