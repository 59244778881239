export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "es": {
        "LABELS": {
          "DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha"])}
        },
        "PLACE_HOLDERS": {
          "DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingrese una Fecha"])}
        }
      }
    }
  })
}
