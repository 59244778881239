import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "d-flex flex-column mb-8 fv-row" }
const _hoisted_2 = { class: "d-flex align-items-center fs-6 fw-bold mb-2" }
const _hoisted_3 = {
  ref: "submitButton",
  type: "submit"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DatePicker = _resolveComponent("DatePicker")!
  const _component_Form = _resolveComponent("Form")!
  const _component_FilterDynamic = _resolveComponent("FilterDynamic")!

  return (_openBlock(), _createBlock(_component_FilterDynamic, {
    width: 350,
    onClearFilters: _ctx.clean,
    onApply: _ctx.apply
  }, {
    default: _withCtx(() => [
      _createVNode(_component_Form, {
        as: "el-form",
        "validation-schema": _ctx.validationSchema,
        ref: "form",
        onSubmit: _ctx.onSubmit
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("label", _hoisted_2, _toDisplayString(_ctx.$t("LABELS.DATE")), 1),
            _createVNode(_component_DatePicker, {
              placehoderDate: _ctx.$t('PLACE_HOLDERS.DATE')
            }, null, 8, ["placehoderDate"])
          ]),
          _withDirectives(_createElementVNode("button", _hoisted_3, null, 512), [
            [_vShow, false]
          ])
        ]),
        _: 1
      }, 8, ["validation-schema", "onSubmit"])
    ]),
    _: 1
  }, 8, ["onClearFilters", "onApply"]))
}