
import { computed, defineComponent, ref } from "vue";
import CashRegisterModule from "@/store/modules/CashRegister/modules/cash-register-store";
import FilterDynamic from "@/components/filters/FiltersDynamic.vue"
import { Form } from "vee-validate";
import * as Validations from "yup";
import { DateStringISO8601 } from "@/core/shared/ValidationRegex";
import DatePicker from "@/components/forms/DatePicker.vue";
import _ from "lodash";
import { getModule } from "vuex-module-decorators";

export default defineComponent({
    components:{
        FilterDynamic
        , Form
        , DatePicker
    },
    setup(){
        const module = getModule(CashRegisterModule);

        const form = ref();
        const submitButton = ref<HTMLButtonElement>();

        const validationSchema = Validations.object().shape({
            date: Validations.string().nullable().trim().matches(DateStringISO8601)
        })

        const clean = () => {
            form.value.resetForm();
        }

        const apply = () => {
            submitButton.value?.click();
        }

        const onSubmit = (values) => {
            module.SHOW_PDF({
                show: true,
                date: values as Date
            });
        }

        return {
            apply
            , clean
            , form
            , validationSchema
            , submitButton
            , onSubmit
        }
    }
})
