import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { Actions } from "./Actions";
import { Mutations } from "./Mutations";
import OptionSelect from "@/models/shared/OptionSelect";
import store from "@/store"
import CashRegisterState from "../../models/CashRegisterState";
import GetCashRegisterResponse from "@/models/cash-register/GetCashRegisterResponse";
import NewRequest from "@/models/cash-register/NewRequest";
import CashRegisterService from "@/core/services/cashregister/CashRegisterServices";
import PaginationResponse from "@/models/general/PaginationResponse";
import GetAllCashRegisterResponse from "@/models/cash-register/GetAllCashRegisterResponse";
import GetConceptTypeResponse from "@/models/cash-register/GetConceptTypeResponse";
import GetSttlementResponse from "@/models/cash-register/GetSttlementResponse";
import ShipComboService from "@/core/services/ship/interfaces/ShipComboService";
import ShipService from "@/core/services/ship/ShipService";
import GetAllCashRegisterCriterialPaginator from "@/models/cash-register/GetAllCashRegisterCriterialPaginator";
import ResponsibleRequest from "@/models/cash-register/ResponsibleRequest";
import ResponsibleResponse from "@/models/cash-register/ResponsibleResponse";
import GetFilterPDF from "@/models/cash-register/GetFilterPDF";


/**
 * Servicio del store (no locolocamos adentro por que seria una propiedad del store)
 */
const service =  new CashRegisterService();
const serviceShip: ShipComboService =  new ShipService();


/**
 * Creamos el store la recuperación de la información de las solicitudes
 */
@Module({ dynamic: true, store, namespaced: true, name: "CashRegister"})
export default class CashRegisterModule extends VuexModule implements CashRegisterState {
    table: PaginationResponse<GetAllCashRegisterResponse[]> = {
        currentPage: 1,
        totalPages: 1,
        totalRecords: 0,
        data: []
    };

    selects = { 
         responsibleOptions: [] as Array<ResponsibleResponse>,
         shipOptions: [] as Array<OptionSelect>,
         conceptType: [] as Array<GetConceptTypeResponse>
        , typePayment: [] as Array<GetConceptTypeResponse>
        , Sttlement: [] as Array<GetSttlementResponse>
    }

    loading = false;

    RequestInfo: GetCashRegisterResponse = {
        amount: 0,
        concept: "",
        conceptTypeId:"",
        id: "",
        liquidationId: "",
        operationType: "1",
        operationTypeId: "",
        person: "",
        personId: "",
        status: "",
        paymentTypeId: ""
    };

    pdf = "";

    showPDF: GetFilterPDF = {
        show: false,
        date: new Date()
    };

    get getShowPDF(){
        return this.showPDF;
    }

    get getbase64PDF(){
        return this.pdf;
    }

    /**
     * Opciones de las liquidaciones
     * @returns Array<GetSttlementResponse>
    */
    get getSettlementOptions(): Array<GetSttlementResponse> {
        return this.selects.Sttlement;
    }

    /**
     * Opciones de los conceptos
     * @returns Array<OptionSelect>
    */
    get getConceptOptions(): Array<GetConceptTypeResponse> {
        return this.selects.conceptType;
    }

    /**
     * Opciones tipo de pagos
     * @returns Array<GetConceptTypeResponse>
    */
    get getTypePayment(): Array<GetConceptTypeResponse> {
        return this.selects.typePayment;
    }

     /**
     * Opciones de los responsables de embarcación
     * @returns Array<OptionSelect>
     */
    get getResponsibleOptions(): Array<OptionSelect> {
        return this.selects.responsibleOptions;
    }

    /**
     * Opciones de las embarcaciones
     * @returns Array<OptionSelect>
     */
    get getShipOptions(): Array<OptionSelect> {
        return this.selects.shipOptions;
    }

    /**
     * Información de las solicitudes
     * @returns Array<OptionSelect>
     */
    get getCashRegisterOptions(): PaginationResponse<GetAllCashRegisterResponse[]> {
        return this.table;
    }

     /**
     * Información de una solicitud
     * @returns Array<OptionSelect>
     */
    get getCashRegisterInfoOptions(): GetCashRegisterResponse {
        return this.RequestInfo;
    }

    @Mutation
    [Mutations.SET_TABLE](value: PaginationResponse<GetAllCashRegisterResponse[]>){
        this.table = value;
    }

    @Mutation
    [Mutations.SET_VALUE_LOADING](value: boolean){
        this.loading = value;
    }

    @Mutation
    [Mutations.SET_CONCEPT_TYPES](concepts: GetConceptTypeResponse[]){
        this.selects.conceptType = concepts;
    }

    @Mutation
    [Mutations.SET_TYPE_PAYMENT](concepts: GetConceptTypeResponse[]){
        this.selects.typePayment = concepts;
    }

    @Mutation
    [Mutations.SET_RESPONSIBLES](responsibles: ResponsibleResponse[]){
        this.selects.responsibleOptions = responsibles;
    }

    @Mutation
    [Mutations.SET_STTLEMENT](sttlement: GetSttlementResponse[]){
        this.selects.Sttlement = sttlement;
        console.log(this.selects.Sttlement);
    }

    @Mutation
    [Mutations.SET_SHIP](ships: OptionSelect[]){
        this.selects.shipOptions = ships;
    }

    @Mutation
    [Mutations.SET_CASH_REGISTER](info: GetCashRegisterResponse){
        this.RequestInfo = info;
    }

    @Mutation
    [Mutations.SET_REGISTER_PDF](base64: string){
        this.pdf = "data:application/pdf;base64," + base64;
    }

    @Mutation
    [Mutations.SET_SHOW_PDF](isShow: GetFilterPDF){
        this.showPDF = isShow;
    }

    /**
     * Información extra de la embarcación
     */
     get liquidationInfo(): (liquidationId: string ) => GetSttlementResponse | undefined  {

        return (liquidationId: string) => {
            const shipInfo = (this.selects
                .Sttlement
                .find(x => x.id === liquidationId));
                
            return shipInfo;
        }
    }

    get responsableInfo(): (responsableId: string ) => ResponsibleResponse | undefined  {

        return (responsableId: string) => {
            const responsible = (this.selects
                .responsibleOptions
                .find(x => x.id === responsableId));
                
            return responsible;
        }
    }
    
    /**
     * Recupera PDF
     * @param id identificador de la liquidación
     */
     @Action({ commit: Mutations.SET_REGISTER_PDF })
     async [Actions.GET_REGISTER_PDF](id: string){
        return (await service.GetReceiptView(id)
        .finally( () => this.context.commit(Mutations.SET_VALUE_LOADING, false))).data ?? "";
     }
    
    @Action
    async [Actions.SAVE_NEW](form: NewRequest){
        this.context.commit(Mutations.SET_VALUE_LOADING, true);//indicamos que esta cargando (TODO debe ser solo el formulario)
        return (await service.saveNewRequest(form)
        .finally( () => this.context.commit(Mutations.SET_VALUE_LOADING, false)))
    }

     @Action
     async [Actions.UPDATE_REQUEST](form: NewRequest){
         this.context.commit(Mutations.SET_VALUE_LOADING, true);//indicamos que esta cargando (TODO debe ser solo el formulario)
         return (await service.updateRequest(form)
         .finally( () => this.context.commit(Mutations.SET_VALUE_LOADING, false)))
     }

     @Action
     async [Actions.PYMENT_REQUEST](id: string){
         this.context.commit(Mutations.SET_VALUE_LOADING, true);//indicamos que esta cargando (TODO debe ser solo el formulario)
         return (await service.paymentRequest(id)
         .finally( () => this.context.commit(Mutations.SET_VALUE_LOADING, false)))
     }

     @Action
     async [Actions.CANCEL_REQUEST](id: string){
         this.context.commit(Mutations.SET_VALUE_LOADING, true);//indicamos que esta cargando (TODO debe ser solo el formulario)
         return (await service.cancelRequest(id)
         .finally( () => this.context.commit(Mutations.SET_VALUE_LOADING, false)))
     }

    @Action({ commit: Mutations.SET_CASH_REGISTER})
    async [Actions.GET_CASH_REGISTER](id: string){
        this.context.commit(Mutations.SET_VALUE_LOADING, true);//indicamos que esta cargando (TODO debe ser solo el formulario)
        return (await service.getCashRegisterInfo(id)
        .finally( () => this.context.commit(Mutations.SET_VALUE_LOADING, false))).data ?? {}
    }

    @Action({ commit: Mutations.SET_SHIP })
    async [Actions.SEARCH_SHIP](filterName: string){ 
        this.context.commit(Mutations.SET_VALUE_LOADING,  true );
        return (await serviceShip.searchShip(filterName)
        .finally( () => this.context.commit(Mutations.SET_VALUE_LOADING, false ))).data ?? []
    }
    
    @Action({ commit: Mutations.SET_CONCEPT_TYPES })
    async [Actions.GET_TYPE_CONCEPT](){ 
        this.context.commit(Mutations.SET_VALUE_LOADING, true);
        return (await service.getTypeConcept()
        .finally( () => this.context.commit(Mutations.SET_VALUE_LOADING, false))).data ?? [{ id: 1, name: "test"}]
    }

    @Action({ commit: Mutations.SET_TYPE_PAYMENT })
    async [Actions.GET_TYPE_PAYMENT](){ 
        this.context.commit(Mutations.SET_VALUE_LOADING, true);
        return (await service.getPaymentType()
        .finally( () => this.context.commit(Mutations.SET_VALUE_LOADING, false))).data ?? [{ id: 1, name: "test"}]
    }

    @Action({ commit: Mutations.SET_RESPONSIBLES })
    async [Actions.SEARCH_RESPONSIBLES](filter: ResponsibleRequest){
        this.context.commit(Mutations.SET_VALUE_LOADING, true);
        return (await service.searchResponsibles(filter)
        .finally( () => this.context.commit(Mutations.SET_VALUE_LOADING, false))).data ?? []
    }

    @Action({ commit: Mutations.SET_STTLEMENT })
    async [Actions.GET_STTLEMENT](filterName: string){
        this.context.commit(Mutations.SET_VALUE_LOADING, true);
        return (await service.getSttlement(filterName)
            .finally( () => this.context.commit(Mutations.SET_VALUE_LOADING, false))).data ?? []
    }

    @Action({ commit: Mutations.SET_TABLE })
    async [Actions.GET_TABLE](criterial: GetAllCashRegisterCriterialPaginator){
        this.context.commit(Mutations.SET_VALUE_LOADING, true);
        return (await service.getTable(criterial)
            .finally( () => this.context.commit(Mutations.SET_VALUE_LOADING, false))).data ?? []
    }
   
    @Action({})
    async [Actions.SHOW_PDF](param: GetFilterPDF){
        this.context.commit(Mutations.SET_SHOW_PDF, param);
    }
}