
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";
import i18n from "@/core/plugins/i18n";

export default defineComponent({
  name: "kt-user-menu",
  components: {
  },
  setup() {
    const router = useRouter();
    const store = useStore();

    const countries = {
      /*en: {
        flag: "media/flags/united-states.svg",
        name: "English",
      },*/
      es: {
        flag: "media/flags/mexico.svg",
        name: "Español",
      },
      /*de: {
        flag: "media/flags/germany.svg",
        name: "German",
      },
      ja: {
        flag: "media/flags/japan.svg",
        name: "Japanese",
      },
      fr: {
        flag: "media/flags/france.svg",
        name: "French",
      },*/
    };

    const signOut = () => {
      store
        .dispatch(Actions.LOGOUT)
        .then((isLogout) => { 
          if(isLogout) { 
            router.push({ name: "sign-in" });
          }
        });
    };
    const setLang = (lang) => {
      localStorage.setItem("lang", lang);
      i18n.global.locale = lang;
    };

    const currentLanguage = (lang) => {
      return i18n.global.locale === lang;
    };

    const currentLangugeLocale = computed(() => {
      return countries[i18n.global.locale];
    });

    //valores del store
    const userEmail = store.state.AuthModule.user?.userName ?? '';

    return {
      signOut,
      setLang,
      currentLanguage,
      currentLangugeLocale,
      countries
      , userEmail
    };
  },
});
