import { Module, Action, Mutation, VuexModule, getModule } from "vuex-module-decorators";
import store from "@/store"
import Modules from "@/store/enums/Modules";
import AccountState from "./models/AccountState";
import ChangePasswordRequest from "@/models/user/ChangePasswordRequest";
import AccountService from "@/core/services/account/AccountService";


enum Mutations {
    SET_LOADING = "SET_LOADING"
}
enum Actions {
    CHANGE_PASSWORD = "CHANGE_PASSWORD"
}
/**
 * Creamos el store del usuario
 */
@Module({ dynamic: true, store, namespaced: true, name: Modules.AccountModule})
export default class AccountModule extends VuexModule implements AccountState {
    showModal = false;
    loading = false;
    

    get getLoading(){
        return this.loading;
    }

    @Mutation
    [Mutations.SET_LOADING](value: boolean){
        this.loading = value;
    }
    
    @Action
    async [Actions.CHANGE_PASSWORD](request: ChangePasswordRequest){
        this.context.commit(Mutations.SET_LOADING, true);
        return accountService.changePassword(request)
        .then((resp) => resp.data)
        .finally(() => this.context.commit(Mutations.SET_LOADING, false));
    }
}

/**
 * Servicio del store 
 */
 const accountService = new AccountService();

 export const accountStore = getModule(AccountModule);