export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "es": {
        "LABELS": {
          "BOAT_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Embarcación"])},
          "LICENSE_TYPE_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de licencia"])},
          "RANGE_DATES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rango de fechas"])}
        },
        "PLACE_HOLDERS": {
          "BOAT_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Busca y selecciona una embarcación"])},
          "LICENSE_TYPE_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecciona un tipo de licencia"])},
          "START_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inicio"])},
          "END_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fin"])}
        }
      }
    }
  })
}
