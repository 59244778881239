
import { defineComponent } from "vue";
import KTMenu from "@/layout/aside/Menu.vue";
import { asideTheme } from "@/core/helpers/config";
import { getLogoLightPath, getLogoAsidePath } from "@/core/helpers/assets";

export default defineComponent({
  name: "KTAside",
  components: {
    KTMenu,
  },
  props: {
    lightLogo: String,
    darkLogo: String,
  },
  setup() {

    return {
      asideTheme,
      getLogoAsidePath
      , getLogoLightPath
    };
  },
});
